import { Container } from "react-bootstrap";
import useWindowSize from "../../hooks/useWindowSize";
import info from "../../configs/info";
import "./CapabilitiesAndAims.scss";

function CapabilitiesAndAims() {
    const { height } = useWindowSize();
    return (
        <Container
            fluid
            className="py-5 px-0 d-flex flex-column justify-content-center row-gap-5"
            style={{ minHeight: height }}
        >
            {/** Capabilities */}
            <div id="capabilities">
                <h2>Capabilities</h2>
                <ul>
                    {info.capabilities.map((capability, index) => (
                        <li key={index}>{capability}</li>
                    ))}
                </ul>
            </div>
            {/** Aims */}
            <div id="aims">
                <h2>Aims</h2>
                <ul>
                    {info.aims.map((aim, index) => (
                        <li key={index}>{aim}</li>
                    ))}
                </ul>
            </div>
        </Container>
    );
}

export default CapabilitiesAndAims;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
import { faGlobe } from "@fortawesome/free-solid-svg-icons/faGlobe";
import { faFacebook } from "@fortawesome/free-brands-svg-icons/faFacebook";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin";
import { useEffect, useState } from "react";
import "./Footer.scss";
import info from "../configs/info";

function Footer() {
    return (
        <footer className="container-fluid px-3 pt-5 pb-3 m-0" id="footer">
            <div className="row">
                <div className="col-12">
                    <h5
                        style={{
                            paddingLeft: "32px",
                            fontFamily: "'Noto Sans', sans-serif",
                            fontWeight: "bold",
                        }}
                    >
                        {info.name}
                    </h5>
                </div>
            </div>
            <div className="row row-gap-3">
                <div className="col-12 col-sm-6">
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faEnvelope} />
                            <a className="mx-1" href={`mailto:${info.email}`}>
                                {info.email}
                            </a>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faPhone} />
                            <a className="mx-1" href={`tel:${info.phone}`}>
                                {info.phone}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="col-12 col-sm-6">
                    <ul>
                        <li>
                            <FontAwesomeIcon icon={faGlobe} />
                            <a
                                className="mx-1"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://${info.website}`}
                            >
                                {info.website}
                            </a>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faLinkedin} />
                            <a
                                className="mx-1"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://${info.linkedin}`}
                            >
                                Linkedin
                            </a>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faGithub} />
                            <a
                                className="mx-1"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://${info.github}`}
                            >
                                GitHub
                            </a>
                        </li>
                        <li>
                            <FontAwesomeIcon icon={faFacebook} />
                            <a
                                className="mx-1"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://${info.facebook}`}
                            >
                                Facebook
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="row mt-5">
                <p className="my-0 text-center">&copy; 2025, {info.name}</p>
            </div>
        </footer>
    );
}

export default Footer;

import { useEffect, useState } from "react";

function getWindowSize() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
}

function useWindowSize() {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        const resizeHandler = () => {
            setWindowSize(getWindowSize());
        };
        window.addEventListener("resize", resizeHandler);

        return () => window.removeEventListener("resize", resizeHandler);
    });

    return windowSize;
}

export default useWindowSize;

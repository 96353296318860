import Container from "react-bootstrap/Container";
import Header from "./components/Header";
import { Outlet } from "react-router-dom";
import Footer from "./components/Footer";
import HeaderSizeContext from "./contexts/HeaderSizeContext";
import useElementSize from "./hooks/useElementSize";
import stylingConfig from "./configs/styling";

function Layout() {
    const [headerSize, cbRef] = useElementSize();
    return (
        <Container fluid className="p-0">
            <div
                className="col-12"
                style={{ height: stylingConfig.headerHeight, width: "100%" }}
                ref={cbRef}
            >
                <Header />
            </div>
            <HeaderSizeContext.Provider
                value={{
                    size: {
                        height: headerSize.height,
                        width: headerSize.width,
                    },
                    set: (newSize) => newSize,
                }}
            >
                <Outlet />
            </HeaderSizeContext.Provider>
            <Footer />
        </Container>
    );
}

export default Layout;

import Badge from "react-bootstrap/Badge";
import Card from "react-bootstrap/Card";
import { Container } from "react-bootstrap";
import EduCardData from "../../types/EduCardData";
import config from "../../configs/styling";
import info from "../../configs/info";
import useWindowSize from "../../hooks/useWindowSize";
import "./Education.scss";

function EducationCard(item: EduCardData) {
    return (
        <Card
            style={{
                width: "50vw",
                minWidth: "250px",
                maxWidth: config.maxEduCardWidth,
            }}
        >
            <Badge className="degreeBadge position-absolute top-0 end-0 m-2">
                {item.degree}
            </Badge>
            <Card.Img
                className="card-img-top"
                src={item.img}
                style={{ aspectRatio: 16 / 9 }}
                alt={item.name}
            />
            <Card.Body>
                <Card.Title>{item.name}</Card.Title>
                <Card.Subtitle>{item.major}</Card.Subtitle>
                <Card.Text>
                    {item.time}
                    {item.expected ? <sup>(*)</sup> : undefined}
                </Card.Text>
            </Card.Body>
            {item.expected ? (
                <Card.Footer className="eduCardNotes">(*) Expected</Card.Footer>
            ) : undefined}
        </Card>
    );
}

function Education() {
    const { height } = useWindowSize();
    return (
        <Container
            fluid
            className="py-5 px-0"
            id="education"
            style={{ minHeight: height }}
        >
            <h2>Education</h2>
            <div className="container-sm">
                <div className="row row-gap-5 justify-content-center">
                    {info.education.map((item) => (
                        <div className="col-12 col-md-6 col-lg-4 d-flex justify-content-center">
                            <EducationCard {...item} />
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    );
}

export default Education;

import { useCallback, useState } from "react";
import SimpleSize from "../types/SimpleSize";

function getElementSize(node: HTMLElement) {
    const computedStyle = getComputedStyle(node);
    const width = node.offsetWidth;
    const height = node.offsetHeight;
    const paddingLeft = parseFloat(computedStyle.paddingLeft);
    const paddingRight = parseFloat(computedStyle.paddingRight);
    const paddingTop = parseFloat(computedStyle.paddingTop);
    const paddingBottom = parseFloat(computedStyle.paddingBottom);
    return {
        width: width - paddingLeft - paddingRight,
        height: height - paddingTop - paddingBottom,
    };
}

function useElementSize() {
    const [size, setElementSize] = useState<SimpleSize>({
        width: 0,
        height: 0,
    });

    const callbackRef = useCallback((node: HTMLElement | null) => {
        if (node !== null) {
            const resizeHandler = () => {
                setElementSize(getElementSize(node));
            };
            const observer = new ResizeObserver(resizeHandler);
            observer.observe(node);

            return () => observer.disconnect();
        }
    }, []);
    return [size, callbackRef] as const;
}

export default useElementSize;

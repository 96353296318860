import Container from "react-bootstrap/Container";
import Home from "./Home";
import Introduction from "./Introduction";
import Education from "./Education";
import CapabilitiesAndAims from "./CapabilitiesAndAims";

function AboutMe() {
    return (
        <section id="about-me">
            <Container fluid className="p-0">
                <Home />
                <Introduction />
                <CapabilitiesAndAims />
                <Education />
            </Container>
        </section>
    );
}

export default AboutMe;

import hcmut from "../assets/hcmut.webp";
import EduCardData from "../types/EduCardData";

const nameTitle = "namle";
const role = "undergraduate";

// Contact information
const name = "Nam Le Mai";
const phone = "(+84) xxx xxx xxx";
const email = "contact@namle.me";
const website = "www.namle.me";
const linkedin = "linkedin.com/in/namle0001";
const github = "github.com/NamLe029";
const facebook = "fb.me/namle0001";

const capabilities = [
    "Reactjs",
    "Node.js",
    "Docker",
    "Linux OS",
    "C programming language",
];

const aims = [
    "Computer Science",
    "Networking",
    "Low-level programming",
    "Reverse engineering",
    "Cryptography",
];

// Education information
const education: EduCardData[] = [
    {
        name: "HCMC University of Technology",
        time: "2024 - 2028",
        major: "Computer Science",
        img: hcmut,
        degree: "Bachelor",
        expected: true,
    },
];

// Brief introduction
const briefIntro =
    "Motivated to explore new things, continuously learning and growing";

const info = {
    nameTitle,
    role,
    name,
    phone,
    email,
    website,
    linkedin,
    github,
    facebook,
    capabilities,
    aims,
    education,
    briefIntro,
};

export default info;

import Container from "react-bootstrap/Container";
import info from "../../configs/info";
import useWindowSize from "../../hooks/useWindowSize";
import { useContext } from "react";
import HeaderSizeContext from "../../contexts/HeaderSizeContext";

import "./Home.scss";

function Home() {
    const headerSize = useContext(HeaderSizeContext);
    const { height } = useWindowSize();
    return (
        <>
            <div
                className="container-sm py-5 d-flex flex-column justify-content-center"
                id="home"
                style={{ minHeight: height - headerSize.size.height }}
            >
                <div>
                    <h1 className="mb-0" id="name-title">
                        {info.nameTitle}
                    </h1>
                    <sub id="job-role">{info.role}</sub>
                    <p id="brief-intro">{info.briefIntro}</p>
                </div>
                <div
                    className="d-flex justify-content-center"
                    style={{ marginTop: height * 0.2 }}
                >
                    <a
                        href="#introduction"
                        className="text-center"
                        id="scroll-down-for-more"
                    >
                        Scroll down for more
                    </a>
                </div>
            </div>
        </>
    );
}

export default Home;

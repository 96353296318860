import { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import "./Header.scss";

function Header() {
    const location = useLocation();
    const [active, setActive] = useState(location.pathname);

    const nav = [
        {
            name: "HOME",
            href: "/",
        },
        {
            name: "PROJECTS",
            href: "/projects",
        },
        {
            name: "BLOGS",
            href: "/blogs",
        },
    ];

    useEffect(() => {
        setActive(location.pathname);
    }, [location]);

    return (
        <nav className="navbar h-100 d-flex justify-content-center px-lg-5">
            {/**Nav items */}
            <ul className="navbar-nav d-flex flex-row">
                {nav.map((item) => (
                    <li className="nav-item" key={item.name}>
                        <a
                            className={clsx("nav-link", "mx-2", {
                                active: active === item.href,
                            })}
                            href={item.href}
                            style={{ color: "inherit" }}
                        >
                            {item.name}
                        </a>
                    </li>
                ))}
            </ul>
        </nav>
    );
}

export default Header;

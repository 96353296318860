import Container from "react-bootstrap/Container";

function NotReleasedYet() {
    return (
        <Container fluid>
            <h1>Not Released Yet</h1>
            <p>Sorry, this feature is not released yet.</p>
        </Container>
    );
}

export default NotReleasedYet;

import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider,
} from "react-router-dom";
import Layout from "./layout";
import AboutMe from "./components/AboutMe/AboutMe";
import NotReleasedYet from "./components/NotReleasedYet";
import NotFound from "./components/NotFound";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Layout />}>
            <Route
                index
                id="home"
                element={
                    <div className="col-12">
                        <AboutMe />
                    </div>
                }
            />
            <Route path="projects" id="projects" element={<NotReleasedYet />} />
            <Route path="blogs" id="blogs" element={<NotReleasedYet />} />
            <Route path="*" id="not-found" element={<NotFound />} />
        </Route>
    )
);

function App() {
    return <RouterProvider router={router} />;
}

export default App;

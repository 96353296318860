import { createContext } from "react";
import SimpleSize from "../types/SimpleSize";

const HeaderSizeContext = createContext<{
    size: SimpleSize;
    set: (oldSize: SimpleSize) => SimpleSize;
}>({
    size: { height: 0, width: 0 },
    set: (newSize: SimpleSize) => newSize,
});

export default HeaderSizeContext;

import Container from "react-bootstrap/Container";
import useWindowSize from "../../hooks/useWindowSize";
import "./Introduction.scss";

function Introduction() {
    const { height } = useWindowSize();
    return (
        <Container
            fluid
            className="px-3 py-5 d-flex justify-content-center align-items-center"
            style={{ minHeight: height }}
            id="introduction"
        >
            <div id="letter-wrapper">
                <p>
                    Welcome! I'm Le Mai Nam, currently a first-year student. I
                    specialize in Computer Science and am willing to enlarge my
                    knowledge about the digital world.
                </p>
                <p>
                    However, I am not limiting myself to a single field. I am
                    eager to challenge myself in various areas beyond computers
                </p>
                <p>
                    If you are interested in collaborating or have any
                    questions, please feel free to{" "}
                    <a href="#footer">contact me.</a>
                </p>
            </div>
        </Container>
    );
}

export default Introduction;

import Container from "react-bootstrap/Container";

function NotFound() {
    return (
        <Container fluid>
            <h1>Not Found</h1>
            <p>Sorry, the page you are looking for does not exist.</p>
        </Container>
    );
}

export default NotFound;
